<template>
  <div class="">
    <transition-group
      name="fade"
      mode="out-in"
      mailaddress="div"
      class="tagsarea textarea field is-grouped is-grouped-multiline"
      :class="{ 'is-borderless': inputBorderless }"
    >
      <span key="mailaddressInput" class="wrapper-input has-padding-right">
        <input
          ref="mailaddressInput"
          type="text"
          v-model="mailaddressInput"
          class="input input-mailaddress"
          placeholder="Add mailaddress"
          @keyup="broadcastInputCheck"
          @keyup.enter="addTag"
        />
        <a
          @click="addTag"
          key="addTagButton"
          class="button is-small is-success"
          :class="{
            'is-success': mailaddressInput,
            'is-disabled': !mailaddressInput
          }"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
        </a>
      </span>
      <div
        v-for="(mailaddress, index) in mMailaddresses"
        :key="'t_' + index"
        class="control"
      >
        <div class="tags has-addons">
          <span class="tag is-link">{{ mailaddress }}</span>
          <a @click="removeTag(index)" class="tag is-delete"></a>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    inputBorderless: {
      type: Boolean,
      default: false
    },
    setFocusInput: {
      type: Boolean,
      default: false
    },
    mailaddresses: {
      type: Array,
      default: function() {
        return []
      }
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      mailaddressInput: '',
      mMailaddresses: this.mailaddresses,
      mailaddressInputPlaceHolder: 'Add mail address',
      timer: null
    }
  },

  created() {},

  computed: {},

  methods: {
    broadcastInputCheck() {
      this.$emit('mailinputChecked', this.mailaddressInput.trim().length === 0)
    },

    /**
     * Add mailaddress to mailaddresses array
     */
    addTag() {
      if (this.mailaddressInput) {
        let mailaddress = this.mailaddressInput

        // TODO: Validate email address
        if (!this.tagAlreadyAdded(mailaddress.trim())) {
          //this.mMailaddresses.unshift(mailaddress.trim())
          this.mailaddressInput = ''
          this.$refs.mailaddressInput.focus()
          this.$emit('mailaddressAdded', mailaddress)
          this.broadcastInputCheck()
        }
      }
    },

    /**
     * Remove mailaddress from array
     */
    removeTag(index) {
      this.$emit('mailaddressDeleted', this.mailaddresses[index])
      //this.mMailaddresses.splice(index, 1)
    },

    /**
     * Check if mailaddress already exists in array
     */
    tagAlreadyAdded(mailaddress) {
      var index = this.mMailaddresses.indexOf(mailaddress)
      if (index > -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';

.row-whyTags {
  display: flex;
  align-items: baseline;
  .message {
    margin-bottom: 0;
    padding: 2px 5px;
  }
}

.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}

.field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  background-color: $white;
  min-height: 80px;
  height: auto;

  &:last-child {
    margin-bottom: $gap;
  }

  .mailaddresses {
    .mailaddress {
      font-size: $size-6;
    }
    a.is-delete {
      color: $text;
    }
  }

  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-mailaddress {
      min-width: 200px;
      width: 200px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}
</style>
